import * as React from 'react'
import Head from 'next/head'
import { Placeholder } from '~/components/Placeholder'

function NotFoundError() {
  return (
    <>
      <Head>
        <link rel="shortcut icon" type="image/png" href="/icon.png" />
      </Head>
      <Placeholder emoji="🚨" text="404 Error" />
    </>
  )
}

export default NotFoundError
